import React from 'react';
import {ProductList} from '../../gallery/components/ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript, MobileFiltersPanelState} from '../../types/galleryTypes';
import s from '../../gallery/components/GalleryApp/GalleryApp.scss';
import ExtendedGridGalleryClasses from './ExtendedGridGallery.scss';
import classNames from 'classnames';
import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../globalPropsContext';
import {IGalleryGlobalProps} from '../../gallery/galleryGlobalStrategy';
import {inlineStyleFix} from '../../styles/inlineStyle';
import 'wicg-inert';
import {CategoriesBreadcrumbs} from '../../category/components/CategoriesBreadcrumbs/CategoriesBreadcrumbs';
import {Hero} from '../../category/components/Hero/Hero';
import {SideFilters} from '../components/SideFilters/SideFilters';
import {Sort} from '../components/Sort/Sort';
import {shouldShowGalleryAppInLayout} from '../../gallery/components/GalleryApp/appUtils';
import {ProductsCounter} from '../components/ProductsCounter/ProductsCounter';
import {MobileFilters} from '../components/MobileFiltersAndSort/MobileFilters/MobileFilters';
import {EmptyGallery} from '../components/EmptyGallery/EmptyGallery';
import {AppliedFilters} from '../../gallery/components/Filters/AppliedFilters/AppliedFilters';
import {ConditionalRender} from '../components/ConditionalRender/ConditionalRender';
import {CategoryHeaderPosition, Experiments, GallerySlotIds} from '../../constants';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {HeroSlotsWrapper} from '../../category/components/Hero/HeroSlotsWrapper';

export enum DataHook {
  Root = 'extended-gallery-app-root',
  Content = 'extended-gallery-app-content',
  Container = 'extended-gallery-app-container',
  HeroContainer = 'extended-gallery-hero-container',
  SideFiltersContainer = 'extended-gallery-side-filters-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'extended-gallery-aside-filters',
  MobileContainer = 'mobile-container',
  Breadcrumbs = 'extended-gallery-breadcrumbs',
  BreadcrumbsComponent = 'extended-gallery-breadcrumbs-component',
  ProductListContainer = 'product-list-container',
  CounterAndSortContainer = 'counter-sort-container',
  AppliedFiltersContainer = 'applied-filters-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class ExtendedGridGalleryAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.scrollToProductIfReturnedFromProductPage();
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: Readonly<IGalleryAppProps>) {
    const {shouldAnnounceFilters, setShouldAnnounceFilters} = this.props.globals.useFiltersAnnouncer;
    if (
      shouldAnnounceFilters &&
      prevProps.globals.useFiltersAnnouncer.shouldAnnounceFilters !== shouldAnnounceFilters
    ) {
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      );
      setShouldAnnounceFilters(false);
    }
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private readonly updateStateForScrollRestorationAndClearQueryParams = () => {
    // Thunderbolt's way to remove query params calls `locationSdkProvider.ts` which calls `history.replaceState` with `null`.
    // `scrollRestorationApi.ts` happens on loading of page and reads `history.state` which now is `null`. So it's logic is to scroll to top.
    // Fix: remove the query params natively, and call `history.replaceState` with the correct `scrollY` position.
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete('scrollToProduct');
    window.history.replaceState({scrollY: window.scrollY}, '', urlObj.toString());
  };

  private scrollToProductIfReturnedFromProductPage() {
    const {
      globals: {scrollToProduct},
    } = this.props;

    if (scrollToProduct) {
      const productItem = document.querySelector(`[data-hook="${DataHook.Root}"] [data-slug="${scrollToProduct}"]`);
      if (productItem) {
        productItem.scrollIntoView({block: 'center'});
        this.updateStateForScrollRestorationAndClearQueryParams();
      }
    }
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private renderHero(classname: string, categoryHeaderPosition: CategoryHeaderPosition) {
    return (
      this.props.globals.isCategoryPage && (
        <ConditionalRender
          by={'showHeroSection'}
          className={classname}
          data-hook={`${DataHook.HeroContainer}-${categoryHeaderPosition}`}>
          <HeroSlotsWrapper>
            <Hero />
          </HeroSlotsWrapper>
        </ConditionalRender>
      )
    );
  }

  private emptyGallery(classnames, responsive) {
    const {textsMap} = this.props.globals;
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={ExtendedGridGalleryClasses.layoutContainer}>
          <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
            <div data-hook={DataHook.Content} className={classnames.content}>
              <div data-hook={DataHook.Container} className={ExtendedGridGalleryClasses.container}>
                <EmptyGallery
                  localeMap={{
                    emptyCategoryPageEditorTitle: textsMap.emptyCategoryPageEditorTitle,
                    emptyCategoryPageEditorSubtitle: textsMap.emptyCategoryPageEditorSubtitle,
                  }}
                  isEditorCategoryPageMode={true}
                  hasFilters={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }
    const {
      styles,
      stylesParams,
      currentCategory,
      numberOfSelectedFilterTypes,
      shouldShowSort,
      shouldShowMobile,
      mobileFiltersPanelState,
      hasSelectedFilters,
      experiments: {shouldRenderSlotsInGallery},
      slots,
      useExperiments,
      isCategoryPage,
    } = this.props.globals;

    const responsive = styles.get(stylesParams.responsive);
    const isDesktop = !shouldShowMobile;
    const isHeroFullStretched =
      isDesktop && styles.get(stylesParams.gallery_categoryHeaderPosition) === CategoryHeaderPosition.StretchedTop;

    const shouldRenderSideFilters =
      isDesktop && (styles.get(stylesParams.galleryShowFilters) || styles.get(stylesParams.gallery_showCategories));
    const shouldRenderDesktopSort = isDesktop && shouldShowSort;
    const shouldShowAppliedFilters = numberOfSelectedFilterTypes > 0;

    const shouldHaveStickySidebar = styles.get(stylesParams.gallery_enableStickySidebar);

    const isPluginInstalledInProductsTopSlot = !!slots?.[GallerySlotIds.GalleryProductsTop];
    const isPluginInstalledInProductsBottomSlot = !!slots?.[GallerySlotIds.GalleryProductsBottom];

    const classnames = {
      app: classNames([s.galleryApp, ExtendedGridGalleryClasses.rightColumn], {
        deviceMobile: shouldShowMobile,
        notCssPerBreakpoint: !this.props.host.usesCssPerBreakpoint,
        [s.galleryContentWidth]: shouldRenderSideFilters,
      }),
      filterContainer: classNames({
        [s.filtersContainerWidth]: shouldRenderSideFilters,
        [s.stickySidebar]: useExperiments.enabled(Experiments.AllowStickySidebarInViewer) && shouldHaveStickySidebar,
      }),
      columnsContainer: classNames(ExtendedGridGalleryClasses.columnsContainer, s.responsiveWidth),
      content: classNames(s.content, s.fullWidth, {
        [s.contentResponsive]: responsive,
      }),
      appliedFiltersContainer: ExtendedGridGalleryClasses.appliedFiltersContainer,
      breadcrumbsContainer: classNames({
        [ExtendedGridGalleryClasses.breadcrumbsContainer]: !shouldShowMobile,
        [ExtendedGridGalleryClasses.breadcrumbsContainerMobile]: shouldShowMobile,
      }),
      stretchedHeroContainer: classNames(
        ExtendedGridGalleryClasses.heroContainer,
        ExtendedGridGalleryClasses.fullStretched
      ),
    };

    if (isCategoryPage && !currentCategory) {
      return this.emptyGallery(classnames, responsive);
    }

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={ExtendedGridGalleryClasses.layoutContainer}>
          <ConditionalRender by={'gallery_showCategoriesBreadcrumbs'}>
            <div className={classnames.breadcrumbsContainer}>
              <CategoriesBreadcrumbs />
            </div>
          </ConditionalRender>
          {isHeroFullStretched &&
            this.renderHero(classnames.stretchedHeroContainer, CategoryHeaderPosition.StretchedTop)}
          <div className={classnames.columnsContainer}>
            {shouldRenderSideFilters && (
              <div data-hook={DataHook.SideFiltersContainer} className={classnames.filterContainer}>
                <SideFilters {...this.props.globals} shouldStretchVertically={false} />
              </div>
            )}
            <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
              <div data-hook={DataHook.Content} className={classnames.content}>
                <div data-hook={DataHook.Container} className={ExtendedGridGalleryClasses.container}>
                  {!isHeroFullStretched &&
                    this.renderHero(
                      ExtendedGridGalleryClasses.heroContainer,
                      CategoryHeaderPosition.MinimizedAboveGallery
                    )}
                  {shouldRenderSlotsInGallery && (
                    <div
                      className={classNames({
                        [ExtendedGridGalleryClasses.galleryProductsTopSlot]: isPluginInstalledInProductsTopSlot,
                      })}>
                      <SlotsPlaceholder slotId={GallerySlotIds.GalleryProductsTop} />
                    </div>
                  )}

                  <div data-hook={DataHook.ProductListContainer}>
                    {shouldShowAppliedFilters && (
                      <ConditionalRender by={'gallery_showAppliedFilters'}>
                        <div
                          data-hook={DataHook.AppliedFiltersContainer}
                          className={classnames.appliedFiltersContainer}
                          role="group"
                          aria-label={this.props.t('appliedFiltersContainerSR')}>
                          <AppliedFilters />
                        </div>
                      </ConditionalRender>
                    )}
                    <div
                      data-hook={DataHook.CounterAndSortContainer}
                      className={ExtendedGridGalleryClasses.countAndFiltersContainer}>
                      <ConditionalRender by={'gallery_showProductsCounter'}>
                        <ProductsCounter />
                      </ConditionalRender>
                      {shouldRenderDesktopSort && <Sort isFloatingView={true} />}
                      {shouldShowMobile && mobileFiltersPanelState !== MobileFiltersPanelState.NONE && (
                        <MobileFilters combineFiltersAndSort={true} isSplitView={true} />
                      )}
                    </div>
                    <ProductList hasFilters={hasSelectedFilters} />
                  </div>
                  {shouldRenderSlotsInGallery && (
                    <div
                      className={classNames({
                        [ExtendedGridGalleryClasses.galleryProductsBottomSlot]: isPluginInstalledInProductsBottomSlot,
                      })}>
                      <SlotsPlaceholder slotId={GallerySlotIds.GalleryProductsBottom} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const ExtendedGridGallery = withGlobals(withTranslations()(ExtendedGridGalleryAppComp));
